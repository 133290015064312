import React from 'react';
import Button from 'components/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { ReactComponent as CalendarLogo } from 'images/calendar.svg';
import { FlowComponentType } from 'routes/FlowRouter';
import { StudentLoanBookAdvisorResult } from 'enums/StudentLoanForgivenessFlowResults';

import { ReactComponent as PercentageIcon } from 'images/percentage-purple.svg';
import { ReactComponent as MoneyIcon } from 'images/money-purple.svg';
import { ReactComponent as CalendarIcon } from 'images/calendar-purple.svg';

import styles from './BookAdvisor.module.scss';

function BookAdvisor({ navigationInfo, handleNext }: FlowComponentType) {
  const handleSchedule = () => {
    analytics.track('Schedule Consultation Link Opened');
    window.open('https://calendly.com/silver-lion-team/student-loan-review', '_blank');
  };
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer icon={<CalendarLogo />} title="Book a free consultation with a student loan advisor">
        <p className={styles.label}>See your savings in just 15 minutes.</p>

        <p className={styles.label}>
          You’ll talk to an expert who will find all of the programs you qualify for and maximize your approval chances.
        </p>

        <div className={styles.advantages}>
          <div className={styles.advantage}>
            <PercentageIcon className={styles.icon} />
            <p className={styles.advantageLabel}>Assuming you qualify, we have close to 100% approval.</p>
          </div>
          <div className={styles.advantage}>
            <MoneyIcon className={styles.icon} />
            <p className={styles.advantageLabel}>We can help even if you’ve missed payments or have poor credit.</p>
          </div>
          <div className={styles.advantage}>
            <CalendarIcon className={styles.icon} />
            <p className={styles.advantageLabel}>We do the work of applying, appealing, and annual certifications.</p>
          </div>
        </div>

        <Button className={styles.button} onClick={handleSchedule}>
          Schedule Consultation
        </Button>
        <Button type={ButtonType.Inverted} onClick={() => handleNext(StudentLoanBookAdvisorResult.NotNow)}>
          Not Now
        </Button>
      </FormContainer>
    </>
  );
}

export default BookAdvisor;
